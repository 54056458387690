<template>
  <div class="content-wrapper">
    <bo-page-title />
    <div class="content pt-0">
      <b-card v-if="isList" no-body>
        <b-card-header>
          <b-row>
            <b-col lg="8">
              <b-form-row>
                <b-col lg="4">
                  <b-form-group>
                    <v-select placeholder=" -- Pilih Status -- " @input="doFill" v-model="filter.status_terlaksana" :options="mrStatus" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                  </b-form-group>
                </b-col>
                <div class="col-md-8">
                    <div class="form-group mb-0">
                            <div class="input-group">
                                <date-range-picker
                                    ref="picker"
                                    :locale-data="datePickerConfig.locale"
                                    :autoApply="datePickerConfig.autoApply"
                                    v-model="dateRange"
                                    :opens="'right'"
                                    :ranges="datePickerConfig.ranges"
                                    @update="updateValues"
                                >
                                    <template v-slot:input="picker">
                                        {{ picker.startDate | date }} - {{ picker.endDate | date }}
                                    </template>
                                </date-range-picker>
                                <div class="input-group-append calendar-group">
                                    <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                                </div>
                            </div>
                    </div>
                </div>
              </b-form-row>
            </b-col>
            <div class="col-lg-4">
                <div class="form-group form-group-feedback form-group-feedback-left mb-0 d-flex">
                    <input class="form-control"
                        v-model="filter.search"
                        @input="doSearch"
                        placeholder="Ketik Nama/No. RM Pasien"
                    />
                    <div class="form-control-feedback">
                        <i class="icon-search4 text-indigo"></i>
                    </div>
                        
                    <b-button
                        class="ml-1 d-inline-flex align-items-center"
                        variant="outline-success"
                        id="resetBtn"
                        @click="doResetData()"
                    >Reset</b-button>
                </div>
            </div>
          </b-row>

        </b-card-header>
        <b-card-body class="p-0">
          <b-table
            :fields="fields"
            :items="dataList||[]"
            :per-page="perPage"
            :primary-key="idKey"
            :busy="!dataList"
            responsive
            show-empty
            striped
          >
            <template #empty>
              <div class="text-center">
                <h4 align="center"><span v-if="filter.status || filter.search">Hasil pencarian tidak ditemukan</span>
                <span v-else>Belum Ada Data {{ pageTitle }} yang terdaftar</span></h4>
              </div>
            </template>
            <template #table-busy>
              <div class="skeletal-comp mb-2"></div>
              <div class="skeletal-comp mb-2"></div>
              <div class="skeletal-comp"></div>
            </template>

            <template #cell(number)="v">
              {{(data.per_page*(data.current_page-1))+v.index+1}}
            </template>
            
            <template #cell(aranjo_no_peserta)="v">
              {{v.value||"-"}}
            </template>

            <template #cell(aranjo_updated_at)="v">
              <span v-if="v">
                {{v.value | moment("DD MMMM YYYY, HH:mm")}} WIB
              </span>
              <span v-else>
                -
              </span>
            </template>

            <template #cell(aranjo_terlaksana)="v">
              <div class="badge badge-success mb-1" v-if="v.value == 1">
                Terlaksana
              </div>
              <div class="badge badge-dark mb-1" v-else>
                Belum Terlaksana
              </div>
            </template>           
           

           

            <template
              #cell(action)="data"
            >
              <div class="list-icons">
                <router-link class="list-icons-item"
                  v-if="moduleRole('edit')"
                  :to="{name: $route.name, params: {pageSlug: data.item[idKey]}}"
                  data-toggle="tooltip" data-placement="top" title="View"><i
                    class="icon-pencil5"></i></router-link>

                <a href="javascript:;" class="list-icons-item"
                  v-if="moduleRole('delete')"
                  @click="doDelete(data.index, data.item)"
                  data-toggle="tooltip" data-placement="top" title="Delete"><i
                    class="icon-bin"></i></a>

                <a href="javascript:;" class="list-icons-item"
                  v-if="moduleRole('edit')"
                  @click="doChangeStatus(data.index, data.item)"
                  data-toggle="tooltip" data-placement="top" title="Change Status"><i
                    class="icon-cog6"></i></a>
              </div>
              <span v-if="!(moduleRole('delete') || !moduleRole('edit'))">-</span>
            </template>
          </b-table>
        </b-card-body>
        <b-card-footer class="pagination-custom" v-if="(dataList||[]).length&&data.total>data.per_page">        
          <b-pagination
            class="mb-0"
            v-model="pageNo"
            :per-page="data.per_page"
            :total-rows="data.total"
          />
        </b-card-footer>
      </b-card>
      <template v-else>
        <Form :row.sync="row" v-bind="passToSub" />
      </template>
    </div>
  </div>
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import Form from './Form.vue'
import Gen from '@/libs/Gen.js'

const _ = global._
const moment = require('moment')

export default {
  extends: GlobalVue,
  components:{Form,DateRangePicker},
  data() {
    return {
        idKey:'aranjo_id',
        statusKey: 'aranjo_terlaksana',
        datePickerConfig: {
            startDate: new Date(),
            endDate: new Date(),
            autoApply: true,
            ranges: {
                'Hari Ini': [new Date(), new Date()],
                '7 Hari Terakhir': [new Date(moment().subtract(6, 'days')), new Date()],
                '30 Hari Terakhir': [new Date(moment().subtract(29, 'days')), new Date()],
            },
            applyClass: 'btn-sm btn-primary',
            cancelClass: 'btn-sm btn-light',
            locale: {
                applyLabel: 'Terapkan',
                cancelLabel: 'Batal',
                direction: 'ltr',
                format: 'mm/dd/yyyy',
                separator: ' - ',
            }
        },
        dateRange: {
            startDate: new Date(),
            endDate: new Date(),
        },
        mrStatus: [
            {text : "Terlaksana", value :"1"},
            {text : "Belum Terlaksana", value :"0"},
        ],
        fields: [
            {
                key: 'number',
                label: '#',
            },
            {
                key: 'aranjo_kode_booking',
                label: 'Kode Booking',
            },
            {
                key: 'aranjo_tanggal_operasi',
                label: 'Tanggal Operasi',
            },
            {
                key: 'mpo_kd_bpjs',
                label: 'Kode Poli',
            },
            {
                key: 'mpo_name',
                label: 'Poli',
            },
            {
                key: 'aranjo_terlaksana',
                label: 'Terlaksana',
            },
            {
                key: 'aranjo_no_peserta',
                label: 'Nomor Peserta',
            },
            {
                key: 'aranjo_updated_at',
                label: 'Terakhir Di update',
            },
            {
                key: 'bu_full_name',
                label: 'Nama Admisi',
            },
            {
                key: 'action',
                label: 'Aksi',
            },
        ],
        mPoli: []
    }
  },
  computed:{
    passToSub(){
      return _.assign(this.passToSubComp||{})
    }
  },
  mounted(){
    this.doConvertDate()          
    this.apiGet()
    if(this.$route.params.pageSlug){
      this.validateModuleRoleCrud()
    }
  },
    filters: {
        date(val) {
            return val ? moment(val).format("D MMM YYYY") : ""
        }
    },
  methods: {
    doChangeStatus(k,v, params={}){
      let id = v[this.idKey]
      let changeStatus = v[this.statusKey] == "1" ? "0" : "1"
      this.$swal({
        title: `Ubah Status ke ${changeStatus=='0' ? 'Belum Terlaksana' : 'Terlaksana'}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText:  `Ya, ubah status ke ${changeStatus=='0'? 'Belum Terlaksana' : 'Terlaksana'}!`,
        cancelButtonText:  `Tidak, kembali`,        
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.loadingOverlay=true

          let slugs=""
          if(params.slugs) slugs = params.slugs

          Gen.apiRest(
            "/do/"+this.modulePage+slugs,
            {data:{type:'status',id:id,status:changeStatus}}, 
            "POST"
          )
            .then(()=>{
              this.loadingOverlay = false 
              v[this.statusKey] = changeStatus        
              this.$swal({
                title: `Status Diubah Menjadi ${changeStatus=='1'? 'Terlaksana' : 'Belum Terlaksana'}.`,
                icon: 'success',
              })
              this.apiGet()
            })
            .catch(err=>{ 
              this.loadingOverlay = false 
              if(err){
                  err.statusType = err.status
                  err.status = "error"
                  err.message = err.response.data.message
                  err.title = err.response.data.title
                  err.messageError = err.message
              }
              this.doSetAlertForm(err)
            })
        }
      })
    },
    doFill(){
      this.doFilter()
    },
    doConvertDate(){
        this.filter.startDate = moment(this.dateRange.startDate).format('YYYY-MM-DD')
        this.filter.endDate = moment(this.dateRange.endDate).format('YYYY-MM-DD')
    },
    doResetData(){
        this.doReset()
        this.dateRange =  {
            startDate: new Date(),
            endDate: new Date(),
        }
        this.doConvertDate()
    },
    updateValues(e){
        this.doConvertDate()
        this.doFill()
    },
    doSearch: _.debounce(function(){
      this.doFilter()
    },1000),
  },
  watch:{
    $route(){
      this.apiGet()
    },
    '$route.params.pageSlug':function(){
      this.$set(this,'filter',{})
    }
  }
}
</script>